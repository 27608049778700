import {
  TagField
} from '@refinedev/antd'

import * as Icons from '@ant-design/icons'
import { Tooltip } from 'antd'

const {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  DownCircleOutlined,
  DownCircleFilled,
  UpCircleOutlined,
  UpCircleFilled,
  ShoppingOutlined,
  ShoppingFilled,
  ShoppingCartOutlined,
  NotificationOutlined,
  NotificationFilled,
  // PoweroffOutlined,
  // SearchOutlined,
  NumberOutlined,
  // IdcardOutlined,
  // ShopTwoTone
  TeamOutlined,
  UserOutlined
} = Icons

/*
export const multiTrasform = (items: any, id: string, trasformFn: any = (a: any) => a): any => {
  const result = []
  for (const key of Object.keys(items)) {
    result.push(trasformFn(items[key][id]))
  }

  return Math.max(...result)
}

export const multiCompareFn = (id: string, trasformDataFn: any = (a: any) => a, getElement = (a: any) => a[id]) => {
  return (a: any, b: any): number => {
    a = trasformDataFn(getElement(a))
    b = trasformDataFn(getElement(b))
    if (a < b) return -1
    if (b < a) return 1
    return 0
  }
} */

const mergeObject = (
  obj1: any,
  obj2: any,
  logicFn: (a: any, b: any) => any = (a: any, b: any): number => Number(a || 0) + Number(b || 0)
): any => {
  const result = { ...obj1 }

  // Aggiungi tutte le chiavi di obj2 al risultato, applicando la logicFn i valori se la chiave esiste già
  for (const key in obj2) {
    if (obj1 && Object.prototype.hasOwnProperty.call(obj1, key)) {
      // Se entrambi i valori sono oggetti, chiamare mergeObject di nuovo
      if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        result[key] = mergeObject(obj1[key], obj2[key])
      } else { // Altrimenti, sommare i valori
        result[key] = logicFn((obj1[key]), (obj2[key]))
      }
    } else { // Se la chiave non esiste in obj1, aggiungila al risultato
      result[key] = obj2[key]
    }
  }

  return result
}

export const getProgressColor = (percent: number): string => {
  if (percent > 90) return 'red' // Rosso
  if (percent > 70) return 'orange' // Arancione
  if (percent > 50) return 'mediumseagreen' // Giallo
  return 'green' // Verde
}

export const getAverage = (arr: number[], output: 'ms' | 's' = 's'): string => {
  if (!arr || arr.length === 0) return ''

  let cumulative = 0
  for (const value of arr) {
    cumulative += value
  }
  const average = cumulative / arr.length
  if (output === 'ms') return String(Math.round(average)) + 'ms'
  return String(Math.round(average / 1000)) + 's'
}

export const cronoGetDetails = (arr: any): any => {
  if (!arr || arr.length <= 1) return ''

  const result = []
  for (let i = 0; i < arr.length - 1; i++) {
    const diff = arr[i + 1] - arr[i]
    result.push(`${i}) ${diff}ms\n`)
  }

  return result
}

export const cronoGet = (arr: any, output: 'ms' | 's' = 's'): any => {
  if (!arr || arr.length <= 1) return ''

  let cumulative = 0
  for (let i = 0; i < arr.length - 1; i++) {
    const diff = arr[i + 1] - arr[i]
    // console.log(diff)
    cumulative += diff
  }

  const average = Math.round(cumulative / (arr.length - 1) / 1000)
  const averageMs = Math.round(cumulative / (arr.length - 1))

  const lastValue = arr[arr.length - 1]
  const last = Math.round((Date.now() - lastValue) / 1000)
  if (last > average * 2 && last > 60 * 1000) return (<span style={{ color: 'red' }}>{last}s ago</span>)

  if (output === 'ms') return String(averageMs) + 'ms'

  return String(average) + 's'
}

export const getInstances = (config: any = {}, platform: string, role: string, country: string): any => {
  const totalArr = Object.values(config).filter((item: any) => item.env.PLATFORM === platform && item.env.ROLE === role && item.env.COUNTRY === country && item.enabled)
  const total = totalArr.length

  const activeArr = totalArr.filter((item: any) => toSec(item.lastSeen) < 180)
  const active = activeArr.length

  const ratio = total === 0 ? 1 : active / total

  const color =
  ratio > 0.70
    ? 'green'
    : ratio > 0.50
      ? 'mediumseagreen'
      : ratio > 0.30
        ? 'orange'
        : ratio > 0.10
          ? 'orangered'
          : 'red'
  const fontWeight = ratio === 0 ? 'bolder' : undefined
  // green: number = 60, mediumseagreen: number = 120, orange: number = 240, orangered: number = 480

  return (<span style={{ color, fontWeight }}>{`${active}/${total}`}</span>)
}

export const getInstancesByLauncher = (config: any = {}, launcher: string): any => {
  const totalArr = Object.values(config).filter((item: any) => item.env.LAUNCHER === launcher && item.enabled)
  const total = totalArr.length

  const activeArr = totalArr.filter((item: any) => toSec(item.lastSeen) < 180)
  const active = activeArr.length

  const ratio = total === 0 ? 1 : active / total

  const color =
  ratio > 0.70
    ? 'green'
    : ratio > 0.50
      ? 'mediumseagreen'
      : ratio > 0.30
        ? 'orange'
        : ratio > 0.10
          ? 'orangered'
          : 'red'
  const fontWeight = ratio === 0 ? 'bolder' : undefined
  // green: number = 60, mediumseagreen: number = 120, orange: number = 240, orangered: number = 480

  return (<span style={{ color, fontWeight }}>{`${active}/${total}`}</span>)
}

export const capitalize = (text: string, separator = ' '): string => {
  if (typeof text !== 'string') return text
  return !text
    ? text
    : text
      ?.trim()
      ?.split(separator)
      ?.map(word => word[0]?.toUpperCase() + word?.substring(1)?.toLowerCase())
      ?.join(separator)
}

export const compareFn = (id: string, trasformDataFn: any = (a: any) => a, getElement = (a: any) => a?.[id]) => {
  return (a: any, b: any): number => {
    a = trasformDataFn(getElement(a))
    b = trasformDataFn(getElement(b))
    if (a < b) return -1
    if (b < a) return 1
    if (!b) return -1
    if (!a) return 1
    return 0
  }
}

export function convertSeconds (seconds: number): string {
  const SECONDS_IN_A_MINUTE = 60
  const MINUTES_IN_AN_HOUR = 60
  const HOURS_IN_A_DAY = 24

  const days = Math.floor(seconds / (SECONDS_IN_A_MINUTE * MINUTES_IN_AN_HOUR * HOURS_IN_A_DAY))
  seconds -= days * SECONDS_IN_A_MINUTE * MINUTES_IN_AN_HOUR * HOURS_IN_A_DAY

  const hours = Math.floor(seconds / (SECONDS_IN_A_MINUTE * MINUTES_IN_AN_HOUR))
  seconds -= hours * SECONDS_IN_A_MINUTE * MINUTES_IN_AN_HOUR

  const minutes = Math.floor(seconds / SECONDS_IN_A_MINUTE)
  seconds -= minutes * SECONDS_IN_A_MINUTE

  const d = days ? `${days}d ` : ''
  const h = hours ? `${hours}h ` : ''
  const m = minutes ? `${minutes}m ` : ''

  return `${d}${h}${m}${seconds}s `
}

export function toSec (v: number): number {
  const sec = v > 1000000000000
    ? (Date.now() - v) / 1000
    : (Date.now() / 1000) - v
  return Math.round(sec)
}

export function toData (v: number): string {
  const ms = v > 1000000000000 ? v : v * 1000
  return (new Date(ms)).toLocaleString('it')
}

export function toColor (v: number, green: number = 60, mediumseagreen: number = 120, orange: number = 240, orangered: number = 480): string {
  const s = toSec(v)
  return s < green ? 'green' : s < mediumseagreen ? 'mediumseagreen' : s < orange ? 'orange' : s < orangered ? 'orangered' : 'red'
}

// inputString = 'venerdì, 12/07/2024 | 17:00'
export const stringToData = (inputString: string): number => {
  if (!inputString) return 0
  // Divisione della stringa in data e ora
  const parts = inputString.split(' | ')
  const datePart = parts[0]

  // Estrai giorno della settimana, data e ora
  const dateMonthYear = datePart.split(', ')[1]
  const [day, month, year] = dateMonthYear.split(dateMonthYear.includes('/') ? '/' : '.')

  // Crea l'oggetto Data
  const newDate = new Date(Number(year), Number(month) - 1, Number(day))
  const milliseconds = newDate.getTime()

  // Output
  // console.log('Data:', day, month, year)
  // console.log('Millisecondi:', milliseconds)
  return milliseconds
}

export interface modus {
  name?: string
  title: string
  price: string
  value: string
  qta: string
  min: string
  max: string
  fansale?: fansaleForm
}

export interface itemTicket {
  title: string
  price: string
  free: any
  single: any
  couple: any
}

interface objTickets {
  color: any
  icon: any
  tags: any
  tagNumber: any
  tagArea: any
  tagSingle: any
  tagCouple: any

}

export interface fansale {
  autoBuy: true
  autoBuyAll: true
  autoCart: true
  id: 'metallica-ippodromo-snai-la-maura-17911233-0'
  lastAlert: 0
  lastScan: 1707728063.648
  negative: string[]
  note: 'metallica-ippodromo-snai-la-maura-17911233 0'
  platform: 'fansale'
  positive: string[]
}

export interface fansaleForm {
  id: string
  modus: number
  negative: any[]
  positive: any[]
  note: string
}

export const getName = (title: string, price: string): string => `${title} ${price}`.replace(/\xA0/g, ' ')

export const ticketsToColor = (item: itemTicket, globalLessThen: string, modus: any, allTickets?: itemTicket[] | null): objTickets => {
  if (!item) return { color: '', icon: '', tags: '', tagNumber: '', tagArea: '', tagSingle: '', tagCouple: '' }
  const area: string = getName(item.title, item.price)
  const isVivaresale = item.couple !== undefined
  const free = item.free
  const single = item.single
  const couple = item.couple
  const tickets = isVivaresale ? couple : free
  const element = modus?.[area]
  const mode = element?.value ?? 'notify'
  const qta: string = element?.qta
  const min: string = element?.min
  const max: string = element?.max
  const disabledByGlobalLessThen = globalLessThen !== '' && globalLessThen !== null && globalLessThen !== undefined && tickets > Number(globalLessThen)
  const disabledByMax = tickets > 0 && Number(max) > 0 && !(tickets <= Number(max))
  const disabledByMin = tickets > 0 && Number(min) > 0 && !(tickets >= Number(min))
  const disabled = disabledByGlobalLessThen || mode === 'disabled' || disabledByMax || disabledByMin
  const simpleColor = tickets === 0 ? 'red' : 'green'
  const color = disabled ? 'default' : simpleColor
  const icon = tickets === 0 ? <CloseCircleOutlined /> : disabledByGlobalLessThen ? <MinusCircleOutlined /> : <CheckCircleOutlined />

  const minWidth = '60px'

  const minMax = (
    <>
      <TagField value={min} color='purple' icon={disabledByMin ? <DownCircleFilled /> : <DownCircleOutlined />} style={disabledByMin ? { fontWeight: 'bold', minWidth: '40px' } : { minWidth: '40px' }} />
      <TagField value={max} color='purple' icon={disabledByMax ? <UpCircleFilled /> : <UpCircleOutlined />} style={disabledByMax ? { fontWeight: 'bold', minWidth } : { minWidth }} />
    </>
  )
  // eslint-disable-next-line multiline-ternary
  const tags = mode === 'cart' ? (
    <>
      <TagField value={`${qta}`} color='gold' icon={disabledByMin || disabledByMax ? <ShoppingCartOutlined /> : <ShoppingCartOutlined />} style={{ minWidth, fontWeight: 'bold' }} />
      {minMax}
    </>
  )
  // eslint-disable-next-line operator-linebreak
    : mode === 'buy' ?
      <>
        <TagField value={`${qta}`} color='green' icon={disabledByMin || disabledByMax ? <ShoppingOutlined /> : <ShoppingFilled />} style={{ minWidth, fontWeight: 'bold' }} />
        {minMax}
      </>
    // eslint-disable-next-line operator-linebreak
      :
      <>
        <TagField value={mode === 'disabled' ? '⚫️' : disabledByGlobalLessThen ? '🌍' : ''} color={disabledByGlobalLessThen || mode === 'disabled' ? 'default' : 'cyan'} icon={disabled ? <NotificationOutlined /> : <NotificationFilled />} style={{ minWidth }} />
        {minMax}
      </>

  const tagNumber = isVivaresale ? null : <TagField value={tickets ?? '🛑'} color={simpleColor} icon={<NumberOutlined />} />

  const tagSingle = isVivaresale ? <TagField value={single ?? '🛑'} color={simpleColor} icon={<UserOutlined />} /> : null
  const tagCouple = isVivaresale ? <TagField value={couple ?? '🛑'} color={simpleColor} icon={<TeamOutlined />} /> : null

  const samePrice = allTickets ? allTickets.filter(ticket => ticket.price === item.price).length > 1 : false
  const tagArea = samePrice ? <Tooltip title='⚠️ Settore con prezzo uguale!'><TagField value={`${area} ⚠️`} color={color} /></Tooltip> : <TagField value={`${area}`} color={color} />

  return { color, icon, tags, tagNumber, tagArea, tagSingle, tagCouple }
}

export function getPhoneUpdateColor (days: number): string {
  if (days < 7) return 'green'
  else if (days < 14) return 'orange'
  else if (days < 21) return 'orangered'
  else return 'red'
}

export function getBalanceColor (numericValueGB: number): string {
  if (numericValueGB >= 200) return 'green'
  else if (numericValueGB >= 100) return 'orange'
  else if (numericValueGB > 0) return 'orangered'
  else return 'red'
}

export function getExpireColor (credit: string, expire: string): string {
  if (!credit || !expire) return 'red'
  const creditValue = parseFloat(credit)
  const [giorno, mese, anno] = expire.split('-')
  const expireDate = new Date(`${anno}-${mese}-${giorno}`)
  const daysToExpire = Math.floor((expireDate.getTime() - Date.now()) / (1000 * 60 * 60 * 24))
  if (creditValue <= 8 && daysToExpire < 0) return 'red'
  else if (creditValue <= 8 && daysToExpire <= 7) return 'orangered'
  else if (creditValue <= 8 && daysToExpire <= 14) return 'orange'
  else return 'green'
}

export const fetchWithAuth = async (url: string, options?: { method?: string, body?: any }): Promise<any> => {
  const { method = 'GET', body } = options ?? {}
  const headers = {
    'Content-Type': 'application/json',
    username: localStorage.getItem('username') ?? '',
    password: localStorage.getItem('password') ?? ''
  }

  const response = await fetch(url, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined
  })

  const text = await response.text()
  try {
    return JSON.parse(text)
  } catch {
    return text
  }
}

export const filterByTimeRange = (
  data: Record<string, any>,
  hours: number,
  prepareData = (a: any): number => a,
  logicOp = (initialValue = 0, value = 0) => initialValue + value
): any => {
  const slots = Object.keys(data ?? {})
  const currentTime = Date.now()
  const timeLimit = currentTime - hours * 60 * 60 * 1000
  let result
  for (const slot of slots) {
    if (Number(slot) > timeLimit) result = logicOp(result, prepareData(data?.[slot]))
  }
  return result
}

export const getDataProxy = (config: any, benchmark: any, hours: number): any => {
  let totalDataProxy = 0
  const dettailsProxy: any = {}
  const partials: any = {}
  for (const instance in config) {
    const item = config[instance]
    if (item.env.PROXY === 'proxy') {
      const platform: string = item.env.PLATFORM
      const role: string = item.env.ROLE
      const country: string = item.env.COUNTRY
      const id = `network-consumption-${platform}-${role}-${country}`
      const data = benchmark?.[id]?.[instance]
      dettailsProxy[instance] = renderDettailsNetwork(data, hours)

      const partial: number = filterByTimeRange(data, hours, (a) => a?.total?.all) ?? 0
      console.log(instance, id, partial)
      totalDataProxy += partial
      if (partial) partials[instance] = partial
    }
  }
  return { totalDataProxy, dettailsProxy, partials }
}

export const renderDettailsNetwork = (obj: any = {}, hours: number): any => {
  const result = filterByTimeRange(obj, hours, a => a, mergeObject) ?? {}
  const types = Object.keys(Object.values(result)?.[0] ?? {})
  const groups = Object.keys(result)

  return (
    <pre>
      <table>
        <tr><td style={{ width: 150 }} />{groups.map(key => <td key={key} style={{ width: 150 }}>{key}</td>)}</tr>
        {types.map(
          type =>
            <tr key={type}>
              <td>{type}</td>
              {groups.map(group =>
                <td key={group}>
                  {(result[group][type] / 1024 ** 3).toFixed(2) + ' GB'}
                </td>)}
            </tr>
        )}
      </table>
    </pre>
  )
}
